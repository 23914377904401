<template>
  <div>
    <header class="header">
      <div class="header-text">付款给 {{ payOrderInfo.mch_short_name }}</div>
      <div class="header-img">
        <img :src="avatar ? avatar : icon_member_default" alt="">
      </div>
    </header>
    <div class="plus-input">
      <!-- ￥字符 货币的符号-->
      <div class="S">
        <img src="../../assets/icon/S.svg" alt="">
      </div>

      <!-- 手写输入框 -->
      <div class="input-c">
        <div class="input-c-div-1">{{ amount }}</div>
        <!-- 数字金额后边的光标 -->
        <!-- <div class="input-c-div" style="background:#07c160"></div> -->
      </div>

    </div>

    <div class="remark-k">
      <div class="remark">
        <div class="title">商品名称:{{ payOrderInfo.title }}</div>
      </div>
      <div v-show="payOrderInfo.remark" class="remark">
        <div class="remark-hui">说明:{{ payOrderInfo.remark }}</div>
      </div>
    </div>

    <div class="bnt-pay">
      <div
        class="bnt-pay-text"
        style="background-color:#1678ff"
        @click="payDo"
      >
        付款
      </div>
    </div>
  </div>
</template>

<script>
import { getPayPackage, getPayOrderInfo, getAliPayPackage } from '@/api/api'
import config from '@/config'
export default {

  data: function() {
    return {
      merchantName: 'jeepay', // 付款的商户默认
      avatar: require('../../assets/images/zfb.jpeg'), // 商户头像默认
      amount: 1, // 支付金额默认
      resData: {},
      wxImg: require('../../assets/images/zfb.jpeg'), // 微信支付图片
      payOrderInfo: {}, // 订单信息
      activeTradeNo: '',
      activeToase: null
    }
  },

  mounted() {
    this.payToken = this.$route.params[config.urlTokenName]
    console.log('正在跳转', this.payToken)

    this.setPayOrderInfo(true) // 获取订单信息 & 调起支付插件
  },

  methods: {
    setPayOrderInfo(isAutoPay) {
      const that = this
      const toast = this.$toast.loading('加载中...', {
        coverColor: 'rgba(0,0,0,0.5)'
      })

      getPayOrderInfo(this.payToken).then(res => {
        this.payOrderInfo = res
        this.amount = (+this.payOrderInfo.fee / 100).toFixed(2)

        if (isAutoPay) {
          // that.pay()
        }

        toast.hide()
      }).catch(res => {
        that.$router.push({ name: config.errorPageRouteName, params: { errInfo: res.message }})
        toast.hide()
      })

      // 创建订单
      getAliPayPackage(this.amount, this.payToken).then(resp => {
        console.log(resp, '----')
        this.activeTradeNo = resp.trade_no
      }).catch(res => {
        that.$router.push({ name: config.errorPageRouteName, params: { errInfo: res.message }})
      })
    },

    // 支付事件
    payDo() {
      this.$toast.loading('加载中...', {
        duration: 1000,
        coverColor: 'rgba(0,0,0,0.5)'
      })
      if (this.activeTradeNo === '') {
        this.$toast.warn('请稍等或重新扫码')
      }

      this.doAlipay()
    },

    doAlipay() {
      const alipayTradeNo = this.activeTradeNo
      const that = this
      // eslint-disable-next-line no-undef
      AlipayJSBridge.call('tradePay', {
        tradeNO: alipayTradeNo
      }, function(data) {
        if (data.resultCode === '9000') {
          that.$router.push({ name: config.successPageRouteName, params: { succInfo: '' }})
          // //重定向
          if (that.payOrderInfo.returnUrl) {
            location.href = that.payOrderInfo.returnUrl
          } else {
            console.log('pay success close do')
            window.AlipayJSBridge.call('closeWebview')
          }

          // ‘8000’：后台获取支付结果超时，暂时未拿到支付结果;
        // ‘6004’：支付过程中网络出错， 暂时未拿到支付结果;
        } else if (data.resultCode === '8000' || data.resultCode === '6004') { // 其他
          that.$router.push({ name: config.errorPageRouteName, params: { errInfo: '支付取消，请重新扫码' }})
          window.AlipayJSBridge.call('closeWebview')
        } else { // /其他异常信息， 需要取消订单
          that.$router.push({ name: config.errorPageRouteName, params: { errInfo: '支付取消，请重新扫码' }})
          window.AlipayJSBridge.call('closeWebview')
        }
      })
    }

  }

}
</script>
<style lang="css" scoped>
 @import './pay.css';
</style>
